'use client'

import { useState, useEffect } from 'react'
import { Button, TextField, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { Star, Zap, Bot, BarChart3, Clock, Gift, Star as StarFilled, StarHalf, ArrowBigDown, ChevronDown } from 'lucide-react'

// Since we're not using Next.js, let's create a simple Link component
const Link = ({ href, className, children }: { href: string, className?: string, children: React.ReactNode }) => (
  <a href={href} className={className}>{children}</a>
)

const FiveStarRating = () => (
  <div className="flex items-center gap-1">
    {[...Array(5)].map((_, i) => (
      <StarFilled key={i} className="h-6 w-6 text-yellow-500 fill-yellow-500" />
    ))}
  </div>
)

const ChalkArrow = () => (
  <svg 
    width="180" 
    height="160" 
    viewBox="0 0 180 160" 
    className="text-white opacity-80"
    style={{ transform: 'rotate(135deg)' }}
  >
    <path
      d="M20 150 Q 20 80, 90 80 L 140 80 L132 70 M140 80 L132 90"
      fill="none"
      stroke="currentColor"
      strokeWidth="8"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{
        filter: 'drop-shadow(0px 0px 2px rgba(255,255,255,0.4))',
        strokeDasharray: '8,8'
      }}
    />
  </svg>
)

// Add this CSS at the top of your component
const gridBackgroundStyle = {
  backgroundImage: `
    radial-gradient(circle at 1px 1px, rgba(255, 255, 255, 0.1) 3px, transparent 0)
  `,
  backgroundSize: '40px 40px',
  position: 'fixed' as const,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 0,
  pointerEvents: 'none' as const
}

export default function Component() {
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState(false)
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  })

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date()
      const target = new Date('2024-12-01T00:00:00')
      const difference = target.getTime() - now.getTime()

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        })
      }
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsLoading(true)
    setError(null)
    
    try {
      const response = await fetch('https://zpqj5dlcpd.execute-api.us-east-1.amazonaws.com/prod/emails', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })

      if (!response.ok) {
        throw new Error('Failed to submit email')
      }

      setSuccess(true)
      setEmail('')
    } catch (err) {
      setError('Failed to submit email. Please try again.')
      console.error('Error:', err)
    } finally {
      setIsLoading(false)
    }
  }

  // Update the Input component's onChange prop
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)

  return (
    <div className="min-h-screen bg-zinc-950 text-zinc-200">
      {/* Grid Background */}
      <div style={gridBackgroundStyle} />
      
      {/* Add position relative to container to layer content above grid */}
      <div className="relative">
        {/* Navigation */}
        <nav className="border-b border-gray-800 bg-[#09090B] relative z-10">
          <div className="absolute inset-0 bg-[#09090B]"></div>
          <div className="container mx-auto px-4 relative z-20">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center space-x-2">
                <Star className="h-6 w-6 text-yellow-500" />
                <span className="text-lg font-semibold">ReviewsMaxx</span>
              </div>
              <div className="hidden md:flex items-center">
                <a href="#pricing" className="text-zinc-400 hover:text-yellow-500 transition-all duration-300 hover:scale-105">
                  Pricing
                </a>
              </div>
            </div>
          </div>
        </nav>

        <div className="container mx-auto px-4 sm:px-6 py-16 sm:py-32">
          <div className="flex flex-col items-center justify-center space-y-20">
            {/* Main Heading */}
            <div className="text-center space-y-4 max-w-4xl mx-auto">
              <div className="flex justify-center mb-4">
                <FiveStarRating />
              </div>
              <h1 className="text-4xl sm:text-5xl md:text-[5.5rem] font-bold tracking-tight leading-none flex flex-col items-center">
                <span className="whitespace-normal text-center sm:whitespace-nowrap">Get More Amazon Reviews</span>
                <span className="bg-yellow-500 text-black px-3 py-1 rounded-lg whitespace-normal text-center sm:whitespace-nowrap mt-2 sm:-mt-2 md:-mt-4">
                  Without Breaking Rules
                </span>
              </h1>
              <p className="text-xl sm:text-2xl text-zinc-400 mt-8 px-4 sm:px-0 text-center">
                Turn your happy customers into glowing reviews with our Amazon-approved automation platform. 
                Boost your rankings and sales the right way.
              </p>
            </div>

            {/* Countdown and Email Form */}
            <div className="w-full max-w-2xl relative mt-20">
              {/* Hand-drawn arrow and text */}
              <div className="absolute -right-40 top-[4.5rem] transform rotate-0 hidden lg:block">
                <div className="relative">
                  <ChalkArrow />
                  <p className="font-['Permanent_Marker'] text-4xl text-white opacity-80 absolute top-16 -right-40" style={{
                    filter: 'drop-shadow(0px 0px 2px rgba(255,255,255,0.4))',
                    letterSpacing: '1px'
                  }}>
                    Get Access!
                  </p>
                </div>
              </div>

              {/* Large Countdown */}
              <div className="flex flex-wrap justify-center gap-4 mb-12">
                {Object.entries(timeLeft).map(([unit, value]) => (
                  <div key={unit} className="text-center">
                    <div className="bg-zinc-800/80 backdrop-blur-sm border border-zinc-700 rounded-xl p-4 sm:p-6 w-[70px] sm:w-32 shadow-lg flex flex-col items-center">
                      <div className="text-3xl sm:text-6xl font-bold text-yellow-500 mb-2 sm:mb-3 font-mono text-center">
                        {value.toString().padStart(2, '0')}
                      </div>
                      <div className="text-xs sm:text-sm uppercase tracking-wider text-zinc-400 font-semibold text-center min-w-full">
                        {unit}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <form onSubmit={handleSubmit} className="space-y-8 px-4 sm:px-0">
                <div className="flex flex-col sm:flex-row gap-4">
                  <TextField
                    type="email"
                    placeholder="Enter your email for early access"
                    value={email}
                    onChange={handleChange}
                    required
                    fullWidth
                    sx={{
                      '& .MuiInputBase-root': {
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        borderColor: 'rgb(39, 39, 42)',
                        color: 'black',
                        fontSize: '1.125rem',
                        padding: '1rem',
                        borderRadius: '1rem',
                        height: '4rem',
                        transition: 'all 0.3s ease',
                        '@media (min-width: 640px)': {  // sm breakpoint
                          fontSize: '1.5rem',
                          padding: '1.5rem',
                          height: '5rem',
                        },
                        '&:hover': {
                          backgroundColor: 'rgba(255, 255, 255, 0.85)',
                          transform: 'scale(1.02)',
                        },
                        '&.Mui-focused': {
                          backgroundColor: 'rgba(255, 255, 255, 0.9)',
                          transform: 'scale(1.02)',
                        }
                      },
                      '& .MuiInputBase-input::placeholder': {
                        color: 'rgba(0, 0, 0, 0.7)',
                        opacity: 1,
                        fontStyle: 'italic'
                      }
                    }}
                  />
                  
                  <Button 
                    type="submit" 
                    variant="contained" 
                    size="large"
                    disabled={isLoading}
                    sx={{ 
                      backgroundColor: '#EAB308',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        backgroundColor: '#F59E0B',
                        transform: 'scale(1.05)',
                      },
                      color: 'black',
                      fontWeight: 600,
                      padding: '0 2rem',
                      fontSize: '1.1rem',
                      borderRadius: '1rem',
                      height: '4rem',
                      whiteSpace: 'nowrap',
                      width: '100%',
                      '@media (min-width: 640px)': {  // sm breakpoint
                        height: '5rem',
                        width: 'auto',
                      }
                    }}
                  >
                    {isLoading ? 'Signing up...' : 'Sign Up'}
                  </Button>
                </div>
              </form>
              {error && (
                <p className="text-red-400 mt-2 text-sm text-center">{error}</p>
              )}
              {success && (
                <p className="text-green-400 mt-2 text-sm text-center">Thanks for signing up! We'll be in touch soon.</p>
              )}
            </div>

            {/* Features Grid */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mt-32 w-full max-w-5xl px-4 sm:px-8">
              <div className="bg-zinc-900/50 p-6 rounded-xl transition-all duration-300 hover:bg-zinc-800/50 hover:scale-105 hover:shadow-xl cursor-pointer">
                <Bot className="h-8 w-8 mb-4 text-yellow-500" />
                <h3 className="text-lg font-semibold mb-2">ToS Compliant Automation</h3>
                <p className="text-zinc-400">Send automated review requests using Amazon's approved communication channels and guidelines.</p>
              </div>
              <div className="bg-zinc-900/50 p-6 rounded-xl transition-all duration-300 hover:bg-zinc-800/50 hover:scale-105 hover:shadow-xl cursor-pointer">
                <Star className="h-8 w-8 mb-4 text-yellow-500" />
                <h3 className="text-lg font-semibold mb-2">Review Monitoring</h3>
                <p className="text-zinc-400">Track reviews across all your ASINs and get instant alerts for new ratings.</p>
              </div>
              <div className="bg-zinc-900/50 p-6 rounded-xl transition-all duration-300 hover:bg-zinc-800/50 hover:scale-105 hover:shadow-xl cursor-pointer">
                <BarChart3 className="h-8 w-8 mb-4 text-yellow-500" />
                <h3 className="text-lg font-semibold mb-2">Analytics Dashboard</h3>
                <p className="text-zinc-400">Analyze sales trends and review performance with detailed reporting.</p>
              </div>
            </div>

            {/* New Section: Additional Features */}
            <div className="w-full max-w-5xl mt-32">
              <h2 className="text-2xl font-bold text-center mb-12">Comprehensive Amazon Seller Tools</h2>
              <div className="grid md:grid-cols-2 gap-8">
                <div className="bg-zinc-900/50 p-6 rounded-xl transition-all duration-300 hover:bg-zinc-800/50 hover:scale-105 hover:shadow-xl cursor-pointer">
                  <h3 className="text-lg font-semibold mb-2">International Support</h3>
                  <p className="text-zinc-400">Support for all major Amazon marketplaces worldwide.</p>
                </div>
                <div className="bg-zinc-900/50 p-6 rounded-xl transition-all duration-300 hover:bg-zinc-800/50 hover:scale-105 hover:shadow-xl cursor-pointer">
                  <h3 className="text-lg font-semibold mb-2">Smart Campaigns</h3>
                  <p className="text-zinc-400">Set campaign rules and timing for optimal review request performance.</p>
                </div>
                <div className="bg-zinc-900/50 p-6 rounded-xl transition-all duration-300 hover:bg-zinc-800/50 hover:scale-105 hover:shadow-xl cursor-pointer">
                  <h3 className="text-lg font-semibold mb-2">Competitor Monitoring</h3>
                  <p className="text-zinc-400">Track competitor ASINs and stay ahead of market trends.</p>
                </div>
                <div className="bg-zinc-900/50 p-6 rounded-xl transition-all duration-300 hover:bg-zinc-800/50 hover:scale-105 hover:shadow-xl cursor-pointer">
                  <h3 className="text-lg font-semibold mb-2">Instant Alerts</h3>
                  <p className="text-zinc-400">Get SMS or email notifications for new reviews and feedback.</p>
                </div>
              </div>
            </div>

            {/* Early Bird Lifetime Access Section */}
            <div className="w-full max-w-4xl mt-32 relative overflow-visible px-4 sm:px-8">
              {/* Background glow effect */}
              <div className="absolute -inset-1 bg-gradient-to-r from-yellow-500 via-yellow-400 to-yellow-500 rounded-2xl blur-2xl opacity-75"></div>
              
              {/* Content */}
              <div className="relative bg-zinc-900/90 p-8 pt-12 rounded-xl border-2 border-yellow-500 transition-all duration-300 hover:bg-zinc-800/90">
                <div className="absolute -top-4 left-1/2 -translate-x-1/2 bg-yellow-500 text-black px-6 py-2 rounded-full text-sm font-bold whitespace-nowrap">
                  LIMITED TIME OFFER
                </div>
                
                <div className="text-center mb-6">
                  <h2 className="text-4xl font-bold mb-4">
                    Early Bird <span className="text-yellow-500">LIFETIME</span> Access
                  </h2>
                  <div className="flex justify-center items-baseline gap-2 mb-4">
                    <span className="text-6xl font-bold text-yellow-500">$199</span>
                    <span className="text-xl text-zinc-400">one-time payment</span>
                  </div>
                  <p className="text-xl text-zinc-300 mb-6">
                    Get unlimited access to ReviewsMaxx forever - <span className="text-yellow-500">never pay again!</span>
                  </p>
                  
                  <div className="flex justify-center mb-8">
                    <Button 
                      variant="contained" 
                      size="large"
                      sx={{ 
                        backgroundColor: '#EAB308',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          backgroundColor: '#F59E0B',
                          transform: 'scale(1.05)',
                        },
                        color: 'black',
                        fontWeight: 600,
                        fontSize: '1.125rem',
                        padding: '0.75rem 2rem',
                      }}
                    >
                      Claim Your Lifetime Access
                    </Button>
                  </div>
                  
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 text-left">
                    <div className="flex items-center gap-2">
                      <Star className="h-5 w-5 text-yellow-500 flex-shrink-0" />
                      <span>100% Amazon ToS compliant</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Star className="h-5 w-5 text-yellow-500 flex-shrink-0" />
                      <span>Unlimited orders/month</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Star className="h-5 w-5 text-yellow-500 flex-shrink-0" />
                      <span>Priority support forever</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Regular Pricing Section - now with modified heading */}
            <div id="pricing" className="w-full max-w-6xl mt-32 px-4 sm:px-8">
              <h2 className="text-2xl sm:text-3xl font-bold text-center mb-8">Regular Pricing Plans</h2>
              <p className="text-zinc-400 text-center mb-16">Available after launch</p>
              
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {/* Starter Plan */}
                <div className="bg-zinc-900/50 p-8 rounded-xl border border-zinc-800 flex flex-col transition-all duration-300 hover:bg-zinc-800/50 hover:scale-105 hover:shadow-xl">
                  <div className="mb-8">
                    <h3 className="text-xl font-semibold mb-2">Starter</h3>
                    <div className="flex items-baseline gap-2">
                      <span className="text-4xl font-bold">$29</span>
                      <span className="text-zinc-400">/month</span>
                    </div>
                    <p className="text-zinc-400 mt-2">Up to 1,000 orders/month</p>
                  </div>
                  
                  <div className="flex-grow space-y-4 mb-8">
                    <div className="flex items-center gap-2">
                      <Star className="h-5 w-5 text-yellow-500" />
                      <span>Review automation</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Star className="h-5 w-5 text-yellow-500" />
                      <span>Custom automation rules</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Star className="h-5 w-5 text-yellow-500" />
                      <span>Email support</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Star className="h-5 w-5 text-yellow-500" />
                      <span>Analytics dashboard</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Star className="h-5 w-5 text-yellow-500" />
                      <span>Competitor monitoring</span>
                    </div>
                  </div>
                  
                  <Button 
                    variant="contained" 
                    fullWidth
                    sx={{ 
                      backgroundColor: '#EAB308',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        backgroundColor: '#F59E0B',
                        transform: 'scale(1.05)',
                      },
                      color: 'black',
                      fontWeight: 600
                    }}
                  >
                    Get Started
                  </Button>
                </div>

                {/* Professional Plan */}
                <div className="bg-zinc-900/50 p-8 rounded-xl border-2 border-yellow-500 flex flex-col relative transition-all duration-300 hover:bg-zinc-800/50 hover:scale-105 hover:shadow-xl">
                  <div className="absolute -top-4 left-1/2 -translate-x-1/2 bg-yellow-500 text-black px-4 py-1 rounded-full text-sm font-semibold">
                    Most Popular
                  </div>
                  
                  <div className="mb-8">
                    <h3 className="text-xl font-semibold mb-2">Professional</h3>
                    <div className="flex items-baseline gap-2">
                      <span className="text-4xl font-bold">$59</span>
                      <span className="text-zinc-400">/month</span>
                    </div>
                    <p className="text-zinc-400 mt-2">Up to 10,000 orders/month</p>
                  </div>
                  
                  <div className="flex-grow space-y-4 mb-8">
                    <div className="flex items-center gap-2">
                      <Star className="h-5 w-5 text-yellow-500" />
                      <span>Review automation</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Star className="h-5 w-5 text-yellow-500" />
                      <span>Custom automation rules</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Star className="h-5 w-5 text-yellow-500" />
                      <span>Priority support</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Star className="h-5 w-5 text-yellow-500" />
                      <span>Advanced analytics</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Star className="h-5 w-5 text-yellow-500" />
                      <span>Competitor monitoring</span>
                    </div>
                  </div>
                  
                  <Button 
                    variant="contained" 
                    fullWidth
                    sx={{ 
                      backgroundColor: '#EAB308',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        backgroundColor: '#F59E0B',
                        transform: 'scale(1.05)',
                      },
                      color: 'black',
                      fontWeight: 600
                    }}
                  >
                    Get Started
                  </Button>
                </div>

                {/* Enterprise Plan */}
                <div className="bg-zinc-900/50 p-8 rounded-xl border border-zinc-800 flex flex-col transition-all duration-300 hover:bg-zinc-800/50 hover:scale-105 hover:shadow-xl">
                  <div className="mb-8">
                    <h3 className="text-xl font-semibold mb-2">Enterprise</h3>
                    <div className="flex items-baseline gap-2">
                      <span className="text-4xl font-bold">$199</span>
                      <span className="text-zinc-400">/month</span>
                    </div>
                    <p className="text-zinc-400 mt-2">100,000+ orders/month</p>
                  </div>
                  
                  <div className="flex-grow space-y-4 mb-8">
                    <div className="flex items-center gap-2">
                      <Star className="h-5 w-5 text-yellow-500" />
                      <span>Review automation</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Star className="h-5 w-5 text-yellow-500" />
                      <span>Custom automation rules</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Star className="h-5 w-5 text-yellow-500" />
                      <span>24/7 priority support</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Star className="h-5 w-5 text-yellow-500" />
                      <span>Advanced analytics</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Star className="h-5 w-5 text-yellow-500" />
                      <span>Competitor monitoring</span>
                    </div>
                  </div>
                  
                  <Button 
                    variant="contained" 
                    fullWidth
                    sx={{ 
                      backgroundColor: '#EAB308',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        backgroundColor: '#F59E0B',
                        transform: 'scale(1.05)',
                      },
                      color: 'black',
                      fontWeight: 600
                    }}
                  >
                    Get Started
                  </Button>
                </div>
              </div>
            </div>

            {/* FAQ Section - moved to the bottom */}
            <div className="w-full max-w-4xl mt-32 mb-20 px-4 sm:px-8">
              <h2 className="text-3xl font-bold text-center mb-12">Frequently Asked Questions</h2>
              
              <div className="space-y-4">
                {/* New "How does it work?" FAQ */}
                <Accordion sx={{
                  backgroundColor: 'rgba(39, 39, 42, 0.5)',
                  color: 'white',
                  borderRadius: '0.75rem',
                  '&:before': { display: 'none' },
                  '&.Mui-expanded': { 
                    margin: '0',
                    backgroundColor: 'rgba(39, 39, 42, 0.8)',
                  },
                  marginBottom: '1rem',
                }}>
                  <AccordionSummary
                    expandIcon={<ChevronDown className="text-yellow-500" />}
                    sx={{ padding: '1rem 1.5rem' }}
                  >
                    <h3 className="text-lg font-semibold">How does ReviewsMaxx work?</h3>
                  </AccordionSummary>
                  <AccordionDetails sx={{
                    padding: '1.5rem 1.5rem',
                    color: 'rgb(161, 161, 170)',
                    fontSize: '1.1rem',
                    lineHeight: '1.7',
                    '& p': {
                      maxWidth: '90%',
                      margin: '0 auto'
                    }
                  } as const}>
                    <p>ReviewsMaxx connects to your Amazon Seller Central account and automatically sends review requests to customers after their purchase. Our smart timing system determines the optimal moment to send requests, and our templates are carefully crafted to maximize response rates while staying 100% compliant with Amazon's guidelines. You can monitor all reviews, set up custom automation rules, and track performance through our intuitive dashboard.</p>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{
                  backgroundColor: 'rgba(39, 39, 42, 0.5)',
                  color: 'white',
                  borderRadius: '0.75rem',
                  '&:before': { display: 'none' },
                  '&.Mui-expanded': { 
                    margin: '0',
                    backgroundColor: 'rgba(39, 39, 42, 0.8)',
                  },
                  marginBottom: '1rem',
                }}>
                  <AccordionSummary
                    expandIcon={<ChevronDown className="text-yellow-500" />}
                    sx={{ padding: '1rem 1.5rem' }}
                  >
                    <h3 className="text-lg font-semibold">Is this compliant with Amazon's Terms of Service?</h3>
                  </AccordionSummary>
                  <AccordionDetails sx={{
                    padding: '1.5rem 1.5rem',
                    color: 'rgb(161, 161, 170)',
                    fontSize: '1.1rem',
                    lineHeight: '1.7',
                    '& p': {
                      maxWidth: '90%',
                      margin: '0 auto'
                    }
                  } as const}>
                    <p>Yes, absolutely! ReviewsMaxx strictly follows Amazon's communication guidelines. We only send review requests through approved channels and maintain full compliance with Amazon's Terms of Service.</p>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{
                  backgroundColor: 'rgba(39, 39, 42, 0.5)',
                  color: 'white',
                  borderRadius: '0.75rem',
                  '&:before': { display: 'none' },
                  '&.Mui-expanded': { 
                    margin: '0',
                    backgroundColor: 'rgba(39, 39, 42, 0.8)',
                  },
                  marginBottom: '1rem',
                }}>
                  <AccordionSummary
                    expandIcon={<ChevronDown className="text-yellow-500" />}
                    sx={{ padding: '1rem 1.5rem' }}
                  >
                    <h3 className="text-lg font-semibold">How does the lifetime access work?</h3>
                  </AccordionSummary>
                  <AccordionDetails sx={{
                    padding: '1.5rem 1.5rem',
                    color: 'rgb(161, 161, 170)',
                    fontSize: '1.1rem',
                    lineHeight: '1.7',
                    '& p': {
                      maxWidth: '90%',
                      margin: '0 auto'
                    }
                  } as const}>
                    <p>Early bird lifetime access is a one-time payment of $199 that gives you unlimited access to ReviewsMaxx forever. This includes all future updates and features - you'll never have to pay again!</p>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{
                  backgroundColor: 'rgba(39, 39, 42, 0.5)',
                  color: 'white',
                  borderRadius: '0.75rem',
                  '&:before': { display: 'none' },
                  '&.Mui-expanded': { 
                    margin: '0',
                    backgroundColor: 'rgba(39, 39, 42, 0.8)',
                  },
                  marginBottom: '1rem',
                }}>
                  <AccordionSummary
                    expandIcon={<ChevronDown className="text-yellow-500" />}
                    sx={{ padding: '1rem 1.5rem' }}
                  >
                    <h3 className="text-lg font-semibold">What marketplaces are supported?</h3>
                  </AccordionSummary>
                  <AccordionDetails sx={{
                    padding: '1.5rem 1.5rem',
                    color: 'rgb(161, 161, 170)',
                    fontSize: '1.1rem',
                    lineHeight: '1.7',
                    '& p': {
                      maxWidth: '90%',
                      margin: '0 auto'
                    }
                  } as const}>
                    <p>ReviewsMaxx supports all major Amazon marketplaces worldwide, including US, UK, EU, Canada, Australia, and more. You can manage multiple marketplaces from a single dashboard.</p>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{
                  backgroundColor: 'rgba(39, 39, 42, 0.5)',
                  color: 'white',
                  borderRadius: '0.75rem',
                  '&:before': { display: 'none' },
                  '&.Mui-expanded': { 
                    margin: '0',
                    backgroundColor: 'rgba(39, 39, 42, 0.8)',
                  },
                  marginBottom: '1rem',
                }}>
                  <AccordionSummary
                    expandIcon={<ChevronDown className="text-yellow-500" />}
                    sx={{ padding: '1rem 1.5rem' }}
                  >
                    <h3 className="text-lg font-semibold">Do you offer a money-back guarantee?</h3>
                  </AccordionSummary>
                  <AccordionDetails sx={{
                    padding: '1.5rem 1.5rem',
                    color: 'rgb(161, 161, 170)',
                    fontSize: '1.1rem',
                    lineHeight: '1.7',
                    '& p': {
                      maxWidth: '90%',
                      margin: '0 auto'
                    }
                  } as const}>
                    <p>Yes! We offer a 30-day money-back guarantee. If you're not completely satisfied with ReviewsMaxx, we'll refund your purchase - no questions asked.</p>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="border-t border-gray-800 mt-32 bg-[#09090B] relative">
        <div className="absolute inset-0 bg-[#09090B]"></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 py-12 relative z-20">
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-8">
            {/* Logo and Description */}
            <div className="space-y-4">
              <div className="flex items-center space-x-2">
                <Star className="h-6 w-6 text-yellow-500" />
                <span className="text-lg font-semibold">ReviewsMaxx</span>
              </div>
              <p className="text-gray-400 text-sm">
                Automated Amazon review requests that stay compliant while maximizing results.
              </p>
            </div>

            {/* Product */}
            <div>
              <h3 className="text-white font-semibold mb-3">Product</h3>
              <ul className="space-y-2">
                <li>
                  <a href="#pricing" className="text-gray-400 hover:text-yellow-500 transition-colors">
                    Pricing
                  </a>
                </li>
                <li>
                  <a href="#faq" className="text-gray-400 hover:text-yellow-500 transition-colors">
                    FAQ
                  </a>
                </li>
              </ul>
            </div>

            {/* Legal */}
            <div>
              <h3 className="text-white font-semibold mb-3">Legal</h3>
              <ul className="space-y-2">
                <li>
                  <a href="/privacy" className="text-gray-400 hover:text-yellow-500 transition-colors">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="/terms" className="text-gray-400 hover:text-yellow-500 transition-colors">
                    Terms of Service
                  </a>
                </li>
              </ul>
            </div>

            {/* Contact */}
            <div>
              <h3 className="text-white font-semibold mb-3">Contact</h3>
              <ul className="space-y-2">
                <li>
                  <a href="mailto:support@reviewsmaxx.com" className="text-gray-400 hover:text-yellow-500 transition-colors">
                    support@reviewsmaxx.com
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* Copyright */}
          <div className="border-t border-gray-800 mt-12 pt-8 text-center text-gray-400 text-sm">
            <p>© {new Date().getFullYear()} ReviewsMaxx. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  )
}